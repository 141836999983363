<template>
    <div>
        <template v-for="parent in parents">
          <v-menu offset-y :key="parent.key">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-3"
                color="primary"
                depressed
                v-bind="attrs"
                v-on="on"
                @click="navigator(parent)"
              >
                {{ parent.title }}
              </v-btn>
            </template>
            <v-list v-if="parent.children.length > 0">
              <v-list-item v-for="(child) in parent.children" :key="child.key">
                <v-list-item-title class="cursor-pointer" @click="navigator(child)" style="font-size: 13px">
                  <v-icon>{{ child.icon }}</v-icon>
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

    </div>
</template>

<script>
    export default {
        name: 'DropdownCaptacao',
        props: {
        },
        data() {
          return {
            parents: [
              {
                key: 'projetos-captacao',
                title: "Projetos",
                route: { name: "projetos-captacao" },
                children: []
              },
              {
                key: 'dispêndios',
                title: 'Dispêndios',
                children: [
                  {
                    key: 'captacao-dispendio-rh',
                    title: 'Dispêndio com RH',
                    route: { name: 'captacao-dispendio-rh' },
                    children: []
                  },
                  {
                    key: 'lancamentos',
                    title: 'Lançamento de despesas',
                    route: { name: 'demais-dispendios' },
                    children: []
                  },
                  {
                    key: 'timesheets-captacao',
                    title: 'Timesheets',
                    route: { name: 'timesheets-captacao' },
                    children: []
                  },
                ]
              },
              {
                key: 'comprovacao-orçamento',
                title: "Resumo (PREV/REAL)",
                route: { name: "comprovacao-orcamento" },
                children: []
              },
            ]
          }
        },
        computed: {
          projetoId() {
            return this.$route.params.id;
          },
        },
        methods: {
          navigator(target) {
            const routeName = target.route.name;
            if(routeName === 'em-dev') {
              return alert('Em desenvolvimento');
            }
            console.log(routeName)
            this.$router.push({ name: routeName, params: { id: this.projetoId }});
          }
        },

    }
</script>